import React, { useEffect } from "react";

import SEO from "src/components/seo.js";

const Apply = ({ location, data }) => {
  useEffect(() => {
    window.location.href = "https://hxouse.slideroom.com/#/login/program/54053";
  }, []);
  return (
    <>
      <SEO title="HXOUSE · Apply" />
    </>
  );
};

export default Apply;
